<template>
  <en-dialog
    class="edit-cash-object"
    width="50%"
    style="text-align: left"
    :visible="visible"
    title="编辑收付信息"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="content">
      <cash-object-pay
        pane-height="484px"
        style="height: 100%"
        :data-id="dataId"
        :type="type"
        :edit-data="data"
        :selected-data-id="selectedPayDataId"
        @change="change"
        @loaded="isLoaded = true"
      >
      </cash-object-pay>
    </div>
    <div slot="footer" style="height: 32px">
      <en-button v-if="isLoaded" @click.native="complete">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>

import cashObjectPay from "./cash-object-pay";

export default {
  name: "edit-cash-object",
  components: {
    cashObjectPay
  },
  props: {
    data: {
      type: Object
    },
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedPayWay: null,
      isLoaded: false
    };
  },
  computed: {
    dataId() {
      return this.data?.nameId;
    },
    type() {
      return this.data?.addType;
    },
    selectedPayDataId() {
      return this.data?.payId;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    complete() {
      const data = {};
      data.name = this.data.name;
      data.nameId = this.data.nameId;
      data.userLogo = this.data.userLogo;
      data.addType = this.data.addType;
      data.addTypeId = this.data.addTypeId;
      data.money = this.data.money;
      data.payAmount = this.data.payAmount;
      data.payId = this.selectedPayWay.id;
      if (this.selectedPayWay.type === "000") {
        data.no = this.selectedPayWay.bankAccountNo;
        data.accountName = this.selectedPayWay.accountName;
        data.bankAddr = this.selectedPayWay.bankAddr;
        data.bankCode = this.selectedPayWay.bankCode;
      }
      data.accountName = data.accountName ? data.accountName : data.name;
      data.way = this.selectedPayWay.way;
      data.isOnlinePay = this.selectedPayWay.type;
      this.$emit("complete", data);
    },
    change(val) {
      this.selectedPayWay = val;
    }
  }
};
</script>

<style lang="scss" scoped>

.edit-cash-object {
  .content {
    width: 100%;
    height:528px;
    position: relative;
  }
}

</style>
