<template>
  <div class="cash-object-bank" @click="clickHandle">
    <div class="content-left">
      <el-radio v-model="selected" :label="dataId">
        <div style="width: 0"></div>
      </el-radio>
    </div>
    <div v-if="isBank" class="bank-content">
      <template v-if="isDefault">
        <div class="default-icon"></div>
        <div class="default-text">默</div>
      </template>
      <div class="icon-bg" :class=" bankIcon === 'yinhanglogo' ? 'icon-small-bg' : ''">
        <div class="icon-bg-content">
          <en-icon :name="bankIcon">
          </en-icon>
        </div>
      </div>
      <div class="content-text">
        <div class="text">{{ userName }}</div>
        <div class="text" style="margin-top: 9px;"> {{ address }} </div>
        <div class="no" style="margin-top: 12px;">{{ cardNumber }}</div>
      </div>
    </div>
    <div v-else class="other-content">
      <div class="other-icon-bg" :style="'backgroundColor:' + otherPayWayIconColor">
        <en-icon :name="otherPayWayIcon" size="18px"></en-icon>
      </div>
      <div class="text">{{ otherPayWayName }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "cash-object-bank",
  props: {
    selectedData: {
      type: [Object, null],
      default: null
    },
    data: {
      type: Object
    },
    isBank: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    selectedData: {
      handler() {
        this.updateSelect();
      }
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  mounted() {
    this.updateSelect();
  },
  computed: {
    // 银行卡号显示
    cardNumber() {
      let bankAccountNo = this.data?.bankAccountNo?.length > 0 ? this.data?.bankAccountNo : this.data?.no;
      if (!bankAccountNo) {
        return null;
      }
      if (bankAccountNo.length > 4) {
        bankAccountNo = bankAccountNo.substr(bankAccountNo.length - 4);
      }
      return `**** **** **** ${bankAccountNo}`;
    },
    // 用户名字
    userName() {
      return this.data?.accountName;
    },
    // 银行地址
    address() {
      return this.data?.bankAddr.length > 0 ? this.data?.bankAddr : this.data?.baddr;
    },
    // 银行图标
    bankIcon() {
      /* eslint-disable */
      const bankImages = {
        "001": "renmin",
        "101": "gongshang",
        "102": "nongye",
        "103": "zhongguo",
        "104": "jianshe",
        "204": "youchu",
        "301": "jiaotong",
        "302": "zhongxin",
        "303": "guangda",
        "304": "huaxia",
        "305": "minsheng",
        "306": "guangfa",
        "307": "pingan",
        "308": "zhaoshang",
        "309": "xingye",
        "310": "pufa",
        "311": "bohai",
        "312": "henfeng",
        "314": "huishang",
        "501": "huifeng",
        "502": "dongya",
        "503": "hensheng",
        "508": "zhaoshang",
        "509": "daxin",
      };
      if (bankImages[this.data.bankId]) {
        return bankImages[this.data.bankId];
      }
      return "yinhanglogo";
    },
    // 收付方式数据id
    dataId() {
      return this.data?.id;
    },
    isDefault() {
      return this.data?.isDefault === "000";
    },
    // 其它支付方式方式名称
    otherPayWayName() {
      return this.data.name;
    },
    // 其它支付方式图标
    otherPayWayIcon() {
      // 000网银，001现金，002支票，003其他，004自定义
      if (this.data.type === "002") {
        return "zhifu-zhipiao";
      } else if (this.data.type === "001") {
        return "zhifu-xianjin";
      } else if (this.data.type === "000") {
        return "zhifu-wangyin";
      }
      return "zhifu-qita";
    },
    otherPayWayIconColor() {
      // 000网银，001现金，002支票，003其他，004自定义
      if (this.data.type === "002") {
        return "#3e90fe";
      } else if (this.data.type === "001") {
        return "#ffad2c";
      } else if (this.data.type === "000") {
        return "#26c393";
      }
      return "#d69e79";
    }
  },
  methods: {
    // 选中变更
    change(val) {
      this.selected = val;
      this.$emit("change", this.data);
    },
    // 更新选中数据
    updateSelect() {
      if (this.selectedData?.id) {
        this.selected = this.selectedData.id;
      } else {
        this.selected = "";
      }
    },
    // 扩大选中点击范围
    clickHandle() {
      this.change(this.dataId);
    }
  }
};
</script>

<style lang="scss" scoped>

.cash-object-bank {
  margin-top: 12px;
  display: flex;
  align-items: center;
  .content-right,
  .content-left {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .content-left {
    width: 24px;
  }
  .other-content,
  .bank-content {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border:1px solid #E8ECF2;
    border-radius:4px;
    padding-left: 13px;
    .en-icon {
      /*background-color: red;*/
    }
    .icon-bg {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      margin-right: -7px;
      margin-left: -10px;
      .icon-bg-content {
        width: 40px;
        height: 60px;
      }
      .en-icon {
        width: 60px;
        height: 60px;
      }
    }
    .icon-small-bg {
      .icon-bg-content {
        display: flex;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #f7bf27;
        width: 40px;
        height: 40px;
        .en-icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .other-content {
    height:70px;
    width:190px;
    .text {
      font-size: 12px;
      line-height: 12px;
      text-align: left;
      color: #636C78;
      margin-left: 12px;
    }
    .other-icon-bg {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .bank-content {
    width:460px;
    height:96px;
    position: relative;
    text-align: left;
    .default-text {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      top: 5px;
      left: 5px;
    }
    .default-icon {
      position: absolute;
      top: 0;
      left: 0;
      border-top: 34px solid #F78429;
      border-right: 34px solid transparent;
    }
    .content-text {
      margin-left: 12px;
      .text {
        font-size: 12px;
        line-height: 12px;
        color: #636C78;
      }
      .no {
        font-size: 14px;
        line-height: 14px;
        font-weight:bold;
        color: #333333;
      }
    }
  }
}

</style>
