<template>
  <div class="add-cash-object">
    <en-dialog
      class="add-cash-object"
      width="1100px"
      style="text-align: left"
      :visible="visible"
      title="新增收付信息"
      @close="close"
      :close-on-click-modal="false"
    >
      <div
        v-en-loading="isLoading"
        :class="isLoading ? 'content':'has-data content'"
      >
        <template v-if="!isLoading">
          <div class="has-data-left">
            <div class="has-data-left-top" :style="datas.length > 0 ? 'border-bottom: none;':''">
              <el-input
                placeholder="搜索关键字"
                v-model="searchText"
                @change="change"
                @keyup.enter.native="search"
              >
                <template slot="append">
                  <en-icon
                    @click.native="search"
                    name="sousuo-danchuang"
                    size="14px"
                    style="color:#a9b5c6;"
                  ></en-icon>
                </template>
              </el-input>
              <el-dropdown v-if="cashObjDatas.length > 1"
                           placement="bottom-start"
                           class="content-edit"
                           style="display: block"
                           :visible-arrow="false"
                           @visible-change="menuChange">
                <en-icon
                  name="tianjia"
                  size="18px"
                  style="color:#4895DF;"
                >
                </en-icon>
                <el-dropdown-menu slot="dropdown">
                  <template v-for="item in cashObjDatas">
                    <el-dropdown-item @click.native="addData(item)">{{ item.cashObjName }}</el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
              <en-icon v-else-if="cashObjDatas.length === 1" @click.native="addData(null)" name="tianjia" size="18px" style="color:#4895DF;"></en-icon>
            </div>
            <div class="has-data-left-bottom" :style="datas.length > 0 ? 'border-top: none;':''">
              <div v-if="datas.length === 0"
                   style="display: flex;height: 100%;justify-content: center;align-items: center;color: #91A1B7;font-size: 14px;">
                <div v-if="!isSearch" style="text-align: center">
                  <img src="@/assets/images/business_noData.png" alt="" width="100%">
                  <div style="line-height: 20px;">您还未有收付对象哦~</div>
                  <div style="line-height: 20px;">
                    <span>请点击 “</span><el-dropdown v-if="cashObjDatas.length > 1"
                                 placement="bottom-start"
                                 class="content-edit"
                                 style="display: inline"
                                 :visible-arrow="false"
                                 @visible-change="menuChange">
                      <span style="color: #3e90fe;">添加</span>
                      <el-dropdown-menu slot="dropdown">
                        <template v-for="item in cashObjDatas">
                          <el-dropdown-item @click.native="addData(item)">{{ item.cashObjName }}</el-dropdown-item>
                        </template>
                      </el-dropdown-menu>
                     </el-dropdown>
                    <span v-else-if="cashObjDatas.length === 1" style="color: #3e90fe" @click="addData(null)">添加</span><span>“</span>
                  </div>
                </div>
                <en-result v-else type="NoData"></en-result>
              </div>
              <div
                v-else
                v-for="(item, index) in datas"
                :key="index"
              >
                <div class="person-header">
                  {{ item.name }}
                </div>
                <div
                  v-for="(node, nodeIdx) in item.nodes"
                  :key="index + '-' + nodeIdx"
                >
                  <div
                    class="person-item"
                    :style="personInfo === node ? 'background-color: #F5F8FC;color:#3e90fe;':''"
                    @click="clickPersonHandle(node)"
                  >
                    <en-user-logo
                      :user-name="node.accountName ? node.accountName : node.name"
                      :image-url="node.userLogo"
                      size="30px"
                    ></en-user-logo>
                    {{ node.accountName ? node.accountName : node.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="has-data-right">
            <cash-object-pay
              :person-info-data="personInfo"
              @change="selctedPayWayChange"
            >
            </cash-object-pay>
          </div>
        </template>
      </div>
      <div slot="footer" style="height: 32px">
        <en-button v-if="datas.length > 0" @click.native="complete">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>

import { cashObject } from "@/api/businessModel";
import { newTabAddBusinessData } from "../../../helper.js";
import cashObjectPay from "./cash-object-pay";
import { BusinessType } from "../../../data/BusinessCommonHeader";

export default {
  name: "add-cash-object",
  components: {
    cashObjectPay
  },
  props: {
    visible: {
      type: Boolean
    },
    cashObjInfo: {
      type: Array
    }
  },
  data() {
    return {
      datas: [],
      historyDatas: [],
      isLoading: false,
      personInfo: null,
      searchText: "",
      selectedPayWay: null,
      visibleAddDataMenu: false,
      isSearch: false
    };
  },
  mounted() {
    this.requestData();
  },
  computed: {
    cashObjDatas() {
      return this.cashObjInfo.filter((item) => parseInt(item.isAllowShow, 10) === 0 && parseInt(item.isAllowAdd, 10) === 0);
    }
  },
  methods: {
    // 获取收付对象历史使用数据
    async requestData() {
      this.isLoading = true;
      const datas = await cashObject.historyPaymentPerson(this.cashObjInfo);
      if (datas?.length > 0) {
        const item1 = { name: "人员", nodes: [] };
        const item2 = { name: "其它", nodes: [] };
        datas.forEach((item) => {
          if (item.objType === "002") {
            item1.nodes.push(item);
          } else {
            item2.nodes.push(item);
          }
        });
        this.historyDatas = [item1, item2];
        this.datas = this.historyDatas.filter((item) => item.nodes.length > 0);
      }
      this.isLoading = false;
      this.updateDefaultPersonHandle();
    },
    updateDefaultPersonHandle() {
      if (this.datas.length > 0) {
        this.clickPersonHandle(this.datas[0].nodes[0]);
      }
    },
    // 通过关键字搜索数据
    async searchData() {
      this.isSearch = true;
      const datas = await cashObject.paymentPerson(this.searchText, this.cashObjInfo);
      if (datas) {
        const item1 = { name: "人员", nodes: [] };
        const item2 = { name: "其它", nodes: [] };
        datas.forEach((item) => {
          if (item.objType === "002") {
            item1.nodes.push(...item.nodes);
          } else {
            item2.nodes.push(...item.nodes);
          }
        });
        this.datas = [];
        if (item1.nodes.length > 0) {
          this.datas.push(item1);
        }
        if (item2.nodes.length > 0) {
          this.datas.push(item2);
        }
        this.updateDefaultPersonHandle();
      } else {
        this.datas = [];
      }
    },
    // 关闭取消操作
    close() {
      this.$emit("close");
    },
    menuChange(visible) {
      this.visibleAddDataMenu = visible;
    },
    // 新增完成组装数据给父组件
    complete() {
      if (!this.selectedPayWay) {
        this.$message("请选择收付方式");
        return;
      }
      const data = {};
      data.name = this.personInfo.name;
      data.nameId = this.personInfo.id;
      data.userLogo = this.personInfo?.userLogo;
      data.addType = this.personInfo.objType;
      data.addTypeId = this.personInfo.objTypeId;
      data.money = "";
      data.payAmount = 0;
      data.payId = this.selectedPayWay.id;
      if (this.selectedPayWay.type === "000") {
        data.no = this.selectedPayWay.bankAccountNo;
        data.accountName = this.selectedPayWay.accountName;
        data.baddr = this.selectedPayWay.bankAddr;
      }
      data.accountName = data.accountName ? data.accountName : this.personInfo.name;
      data.way = this.selectedPayWay.way;
      data.isOnlinePay = this.selectedPayWay.type;
      this.$emit("complete", data);
    },
    // 添加人员收付对象数据
    addData(info) {
      if (!info) {
        info = this.cashObjDatas[0];
      }
      //  先这么写死,这里有问题,需要拿正确的参数
      newTabAddBusinessData({
 objectType: info.objType, objectTypeId: info.objTypeId, businessType: BusinessType.pureBasic, isSF: 1
});
      // 收付对象页签增加数据的回调 点击新增才监听, 拿到值之后移除监听.
      window.addEventListener("storage", this.businessDetail_newCashObjectInfo);
    },
    businessDetail_newCashObjectInfo(event) {
      // FIXME:刷新页面 移除监听. 页面销毁也需要移除, 当前的弹窗实现方式没有销毁页面. 建议在最外层v-if下
      if (event && event.key === "businessDetail_newCashObjectInfo") {
        console.log("收付对象新增回调");
        // 新增收付对象 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_newCashObjectInfo);
      }
    },
    // 搜索
    search() {
      if (this.searchText.length === 0) {
        this.$message("请输入搜索关键字");
        return;
      }
      this.personInfo = null;
      this.selectedPayWay = null;
      this.searchData();
    },
    // 搜索关键字变更
    change(val) {
      if (val.length === 0) {
        this.isSearch = false;
        this.datas = this.historyDatas.filter((item) => item.nodes.length > 0);
        this.selectedPayWay = null;
        this.personInfo = null;
        this.updateDefaultPersonHandle();
      }
    },
    // 点击人员数据，加载人员收付详情数据
    clickPersonHandle(data) {
      if (data.id !== this.personInfo?.id) {
        this.selectedPayWay = null;
      }
      this.personInfo = data;
    },
    // 支付方式变更
    selctedPayWayChange(data) {
      this.selectedPayWay = data;
    }
  }
};
</script>

<style lang="scss" scoped>

.add-cash-object {
  .content {
    height:528px;
    max-width: 100%;
  }
  .has-data {
    display: flex;
    justify-content: space-between;
    .has-data-left {
      width: 310px;
      min-width: 310px;
      background:#FFFFFF;
      border:1px solid #E8ECF2;
      border-radius:4px;
      .has-data-left-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 11px;
        margin-top: 11px;
        margin-bottom: 11px;
        /deep/ .el-input-group__append {
          padding-left: 10px;
          padding-right: 10px;
          background-color: #ffffff;
          border-color: #e8ecf2 !important;
          line-height: 100%;
        }
        .el-input {
          flex: 1;
          margin-right: 10px;
        }
      }
      .has-data-left-bottom {
        border-top: 1px solid #E8ECF2;
        overflow: auto;
        white-space: nowrap;
        height: 460px;
        .person-header {
          background-color: #F6FAFD;
          border-bottom: 1px solid #E8ECF2;
          border-top: 1px solid #E8ECF2;
          padding-left: 12px;
          font-size: 12px;
          color: #636c78;
        }
        .person-item {
          display: flex;
          align-items: center;
          padding-left: 12px;
          height: 40px;
          font-size: 12px;
          color: #636c78;
          .en-user-logo {
            margin-right: 12px;
          }
        }
      }
    }
    .has-data-right {
      flex: 1;
      margin-left: 10px;
    }
  }
}

</style>
