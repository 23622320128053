<template>
  <div class="cash-object">
    <add-cash-object
      :visible="visiableAddData"
      :cash-obj-info="cashObjInfo"
      @close="visiableAddData = false"
      @complete="addDataComplete">
    </add-cash-object>
    <editCashObject
      v-if="visiableEditData"
      :visible="visiableEditData"
      :data="editedData"
      @complete="editDataComplete"
      @close="visiableEditData = false"
    >
    </editCashObject>
    <el-collapse v-model="showContent" @change="changeContent">
      <el-collapse-item name="1">
        <template slot="title">
        <div class="header">
          <!-- 占行控件样式 -->
          <en-icon  name="iconshoufuduixiang-biaoti" size="14px" style="margin-right:5px;color:#A871F6" ></en-icon>
          <div class="header-title" v-if="!column.required">{{ title }}</div>
          <div class="header-title header-title2" v-else>{{ title }}</div>
          <en-icon  name="iconzhankai" color="#A9B5C6" class="drop-down" :style="{transform: showContent.length ===  0 ? 'rotate(270deg)':'rotate(0deg)'}" ></en-icon>
          </div>
        </template>
        <div  class="content">
        <div class="cash-object-items" v-if="column.editable" @click="visiableAddData = true">
          <span class="cash-object-items-add">
            <span class="cash-object-items-addbtn">+</span> 新增
          </span>

        </div>
          <cash-object-item
            v-for="(item, index) in datas"
            :key="index"
            :data="item"
            :column="column"
            @delete="deleteData"
            @edit="editDataHandle"
            @change="changeDataHandle"
          >
          </cash-object-item>
        </div>
  </el-collapse-item>
  </el-collapse>
  </div>
</template>

<script>

import { cashObject } from "@/api/businessModel";
import cashObjectItem from "./cash-object-item";
import addCashObject from "./add-cash-object";
import editCashObject from "./edit-cash-object";
import { AssignmentOptions, BusinessDataOpenType } from "../../../data/BusinessCommonHeader";

export default {
  name: "cash-object",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {
          data: [],
          name: "收付对象"
        };
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    cashObjectItem,
    addCashObject,
    editCashObject
  },
  data() {
    return {
      showContent: ["1"],
      datas: [],
      visiableEditData: false,
      visiableAddData: false,
      editedData: null
    };
  },
  created() {
    console.log(11111, this.column);
    console.log(22222, this.businessData);
    if (this.column.columnValue) {
      this.datas = this.column.columnValue;
    } else {
      const mdData = this.businessData.mdData.originData;
      if (!mdData[this.column.field]) {
        this.datas = [];
        mdData[this.column.field] = this.datas;
      } else {
        this.datas = mdData[this.column.field];
      }
      this.column.columnValue = this.datas;
    }
    if (this.businessData.openType === BusinessDataOpenType.add && this.column.autoAccount === 1) {
      this.requestData();
    }
    this.updateAutoCascadeDatas();
  },
  computed: {
    title() {
      return this.column?.name;
    },
    cashObjInfo() {
      return this.column?.cashObjInfo;
    }
  },
  methods: {
    changeContent(e) {
      console.log(e);
    },
    async requestData() {
      const userInfo = this.$store.getters.userInfo;
      const data = await cashObject.paymentPersonDetail(userInfo.userId);
      const bankInfo = userInfo.bankList.filter((item) => item.isDefault === "000");
      const defaultBankInfo = {};
      if (bankInfo.length > 0) {
        defaultBankInfo.isOnlinePay = "000";
        defaultBankInfo.way = "网银";
        defaultBankInfo.no = bankInfo[0].bankAccountNo;
        // defaultBankInfo.bankId = bankInfo[0].bankId;
        // defaultBankInfo.bankName = bankInfo[0].bankName;
        // defaultBankInfo.bankCode = bankInfo[0].bankCode;
        // defaultBankInfo.bankAddr = bankInfo[0].bankAddr;
        defaultBankInfo.baddr = bankInfo[0].bankAddr;
        // defaultBankInfo.cityCode = bankInfo[0].cityCode;
        // defaultBankInfo.cityName = bankInfo[0].cityName;
        defaultBankInfo.accountName = bankInfo[0].accountName;
        defaultBankInfo.payId = bankInfo[0].id;
      } else {
        defaultBankInfo.accountName = userInfo.name;
        defaultBankInfo.isOnlinePay = "001";
        defaultBankInfo.way = "现金";
      }
      defaultBankInfo.refType = "000";
      defaultBankInfo.addType = "002";
      defaultBankInfo.userLogo = userInfo.userLogo;
      defaultBankInfo.nameId = userInfo.userId;
      defaultBankInfo.name = defaultBankInfo.accountName;
      defaultBankInfo.money = "0";
      const otherInfo = data.otherPay.filter((item) => item.type === defaultBankInfo.isOnlinePay);
      if (otherInfo.length > 0) {
        if (defaultBankInfo.isOnlinePay === "001") {
          defaultBankInfo.id = otherInfo[0].id;
        }
        defaultBankInfo.way = otherInfo[0].name;
        this.addDataComplete(defaultBankInfo);
      }
    },
    updateAutoCascadeDatas() {
      if (this.column.isCascade === 0 && this.column.prefixField.length > 0) {
        const infos = this.businessData.mainInfos.filter((item) => item.uuid === this.column.prefixField);
        if (infos.length === 0) { return; }
        infos[0].updateCashObject = (info, datas) => {
            const mainData = Array.isArray(datas) && datas.length > 0 ? datas[0] : datas;
            if (!mainData) { return; }
            const bankInfos = mainData.bankInfo;
            if (!Array.isArray(bankInfos)) { return; }
            if (bankInfos.length === 0) { return; }
            const defaultBankInfos = bankInfos.filter((item) => {
            const isDefaultDatas = item.isDefault;
               return isDefaultDatas.length > 0 && isDefaultDatas[0].id === "000";
            });
            const bankData = defaultBankInfos.length > 0 ? defaultBankInfos[0] : bankInfos[0];
            const bankInfo = {};
            if (Array.isArray(bankData.bankCode) && bankData.bankCode.length > 0) {
              bankInfo.baddr = bankData.bankCode[0].name;
            }
            bankInfo.accountName = mainData.name;
            bankInfo.isOnlinePay = "000";
            bankInfo.way = "网银";
            bankInfo.no = bankData.bankAccountNo;
            bankInfo.refType = "000";
            bankInfo.payId = bankData.id;
            bankInfo.addType = "002";
            bankInfo.nameId = mainData.id;
            bankInfo.name = bankInfo.accountName;
            bankInfo.money = "0";

            if (defaultBankInfos.length > 0) {
              bankInfo.isDefault = "000";
            }
            this.datas.splice(0, this.datas.length);
            this.datas.push(bankInfo);
            this.updateValue();
        };
      }
    },
    // 删除收付对象数据
    deleteData(data) {
      const index = this.datas.indexOf(data);
      this.datas.splice(index, 1);
      this.updateValue();
    },
    // 编辑收付对象数据，弹框
    editDataHandle(data) {
      this.editedData = data;
      this.visiableEditData = true;
    },
    // 编辑数据完成后
    editDataComplete(data) {
      const index = this.datas.indexOf(this.editedData);
      this.datas.splice(index, 1, data);
      this.visiableEditData = false;
      this.updateValue();
    },
    // 添加收付对象后
    addDataComplete(data) {
      this.visiableAddData = false;
      if (this.column.multipleFlag === 0) {
        this.datas.push(data);
      } else {
        this.datas.splice(0, this.datas.length);
        this.datas.push(data);
      }
      this.updateValue();
    },
    updateValue() {
      this.column.columnValue = this.datas;
      this.column.updateValueWithOptions(this.datas, AssignmentOptions.manual);
    },
    changeDataHandle(data) {
      this.businessData?.templateHandle?.calculateExecute(this.column, data);
    }
  }
};
</script>

<style lang="scss" scoped>

.cash-object-items {
  color: #3E90FE;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  width: 400px;
  height: 152px;
  font-size:14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(62,144,254,0.5);
  border-radius: 4px;
  .cash-object-items-add{
    font-size:14px;
  }
  .cash-object-items-addbtn{
    font-size:16px;
    vertical-align: inherit;
  }
  &:hover {
    .content-edit {
      display: block;
    }
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
}
/deep/ .el-collapse {
    // border-top: none;
    border-bottom: none;
  }

/deep/.el-collapse-item__header .el-icon-arrow-right{
        display: none !important;
    }
.cash-object {
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 0;
    .header-title {
      color: #1A1C1E;
      font-size:14px;
      font-weight:bold;
    }
    .header-title2::after{
      content: "*";
      color: #ff3e3e;
      margin-left: 4px;
    }
    .drop-down{
      margin-left: 5px;
      width:12px;
      height:12px;
    }
    .header-add {
      display: inline-flex;
      align-items: center;
      margin-left: 11px;
      .en-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        color: #4895DF;
      }
      .header-add-title {
        color: #636C78;
        font-size:12px;
        font-weight:400;
      }
    }
  }
  .content {
    display: flex;
    margin-left: -20px;
    margin-top: -20px;
    flex-wrap: wrap;
  }
}

</style>
