<template>
  <div class="cash-object-pay" v-en-loading="isLoading">
    <add-custom-bank-data
      :visible-dig="visible"
      :data="personInfoData"
      @close="visible = false"
      @complete="addDataComplete"
    >
    </add-custom-bank-data>
    <div v-if="personInfoData" class="person-info">
      <en-user-logo
        :user-name="accountName"
        :image-url="userLogo"
        size="40"
      ></en-user-logo>
      <div>{{ accountName }}</div>
    </div>
    <el-tabs v-if="data" v-model="activeName">
      <el-tab-pane label="网银支付" name="bank">
        <div class="bank-pane" :style="'max-height:'+paneHeight">
          <cash-object-bank
            v-for="(item, index) in bankPay"
            :key="index"
            :selectedData="selectedData"
            :data="item"
            @change="change"
          >
          </cash-object-bank>
          <div v-if="canAddData" class="add-bank">
            <en-button
              type="text"
              icon="tianjia-anniutoubu"
              icon-color="#3e90fe"
              style="color:#3e90fe;"
              @click="addDataHandle"
            >添加</en-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="其它支付" name="other">
        <div class="other-may" :style="'max-height:'+paneHeight">
          <cash-object-bank
            v-for="(item, index) in otherPay"
            :key="index"
            :selectedData="selectedData"
            :data="item"
            :is-bank="false"
            @change="change"
          >
          </cash-object-bank>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import { cashObject } from "@/api/businessModel";
import cashObjectBank from "./cash-object-bank";
import addCustomBankData from "./add-custom-bank-data";

export default {
  name: "cash-object-pay",
  components: {
    cashObjectBank,
    addCustomBankData
  },
  props: {
    editData: {
      type: Object
    },
    dataId: {
      type: String
    },
    type: {
      type: String
    },
    personInfoData: {
      type: [Object]
    },
    selectedDataId: {
      type: String
    },
    paneHeight: {
      type: String,
      default: "390px"
    }
  },
  data() {
    return {
      activeName: "bank",
      isLoading: false,
      data: null,
      selectedData: null,
      visible: false
    };
  },
  watch: {
    dataId: {
      handler() {
        this.requestData();
      }
    },
    personInfoData: {
      handler() {
        this.requestData();
      }
    }
  },
  computed: {
    // 网银支付数据
    bankPay() {
      return this.data?.bankPay;
    },
    // 其它支付方式数据
    otherPay() {
      return this.data?.otherPay.filter((item) => item.type !== "000");
    },
    // 能否添加数据，除人员外的可以添加银行数据
    canAddData() {
      return this.personInfoData && this.personInfoData?.objType !== "002";
    },
    // 收付名字
    accountName() {
      return this.personInfoData?.accountName ? this.personInfoData?.accountName : this.personInfoData?.name;
    },
    // 人员头像
    userLogo() {
      return this.personInfoData?.userLogo;
    }
  },
  mounted() {
    this.requestData();
  },
  methods: {
    // 请求数据
    async requestData() {
      this.data = null;
      this.selectedData = null;
      const dataId = this.personInfoData ? this.personInfoData.id : this.dataId;
      const type = this.personInfoData ? this.personInfoData.objType : this.type;
      if (!dataId || dataId?.length === 0) {
        return;
      }
      this.isLoading = true;
      const data = await cashObject.paymentPersonDetail(dataId, type);
      data?.bankPay?.forEach((item) => {
        item.type = "000";
        this.updateDefaultSelected(item, "bank", "网银");
      });
      data?.otherPay?.forEach((item) => {
        if (item.type === "000") {
          return;
        }
        this.updateDefaultSelected(item, "other", item.name);
      });
      this.data = data;
      this.isLoading = false;
      this.$emit("loaded");
    },
    updateDefaultSelected(item, activeName, way) {
      if (!item.way) {
        item.way = way;
      }
      if (this.selectedDataId && this.selectedDataId === item.id) {
        this.change(item);
        this.activeName = activeName;
      }
    },
    // 选中数据，并通知父组件
    change(val) {
      this.selectedData = val;
      this.$emit("change", val);
    },
    // 填写银行数据
    addDataHandle() {
      this.visible = true;
    },
    // 添加银行数据完成后处理数据
    addDataComplete() {
      this.visible = false;
      this.requestData();
    }
  }
};
</script>

<style lang="scss" scoped>

.cash-object-pay {
  position: relative;
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 100%;
  background:#FFFFFF;
  border:1px solid #E8ECF2;
  border-radius:4px;
  /deep/ .el-tabs__item {
    width: 100px;
    text-align: center;
    padding: 0;
    color: #333333;
  }
  /deep/ .el-tabs__item.is-active {
    color: #3e90fe;
    font-weight: bold;
  }
  .person-info {
    display: flex;
    background-color: #F5F7FA;
    height:72px;
    align-items: center;
    border:1px solid #E8ECF2;
    border-radius:4px;
    padding: 16px;
    color: #636C78;
    font-size:12px;
    margin-top: 10px;
    .en-user-logo {
      margin-right: 10px;
    }
  }
  .add-bank {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border:1px solid #E8ECF2;
    border-radius:4px;
    justify-content: center;
    margin-left: 24px;
    width:460px;
    height:96px;
    margin-top: 12px;
  }
  .other-may {
    display: inline-flex;
    margin-left: -12px;
    .cash-object-bank {
      margin-left: 12px;
    }
  }
  .other-may,
  .bank-pane {
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    min-width: 484px;
  }
  .bank-pane {
    display: flex;
    padding-bottom: 20px;
  }
  .el-tab-pane {
    padding-top: 8px;
  }

}

</style>
