<template>
  <div class="cash-object-item">
    <el-dropdown v-if="column.editable"
                 placement="bottom-start"
                 class="content-edit"
                 :style="menuVisible ? 'display: block':''"
                 :visible-arrow="false"
                 @visible-change="menuChange">
      <en-icon name="bianji1" size="16" style="color: #3e90fe"></en-icon>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="editData">编辑</el-dropdown-item>
        <el-dropdown-item @click.native="deleteData">删除</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="content-top">
      <en-user-logo
        :user-name="userName"
        :image-url="userLogo"
        size="40"
      ></en-user-logo>
      <div class="content-top-text">
        <div class="content-top-text-name">
          {{ userName }}
        </div>
        <div v-if="address" class="content-top-text-address">
          {{ address }}
        </div>
        <div v-if="cardNumber" class="content-top-text-number">
          {{ cardNumber }}
        </div>
      </div>
    </div>
    <div class="content-bottom">
      <div class="content-bottom-text"> {{ way }} </div>
      <div class="content-bottom-input">
        <en-input-number
          v-model.number="data.money"
          @change="change"
          :precision="2"
          :disabled="column.editable === false"
          placeholder="请输入金额"
        >
          <template slot="suffix">元</template>
        </en-input-number>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "cash-object-item",
  props: {
    column: {
      type: Object
    },
    data: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data() {
    return {
      menuVisible: false
    };
  },
  computed: {
    // 用户头像
    userLogo() {
      return this.data?.userlogo;
    },
    // 用户名字
    userName() {
      return this.data?.name;
    },
    // 银行地址
    address() {
      return this.data?.baddr;
    },
    // 是否是人员
    isPerson() {
      return this.data?.addType === "002";
    },
    // 银行卡号
    cardNumber() {
      if (!this.data?.no) {
        return null;
      }
      let no = this.data?.no;
      if (no.length > 4) {
        no = no.substr(no.length - 4);
      }
      return `**** **** **** ${no}`;
    },
    // 支付方式
    way() {
      return this.data?.way;
    }
  },
  methods: {
    /**
     * 编辑数据，由父组件处理
     */
    editData() {
      this.$emit("edit", this.data);
    },
    change() {
      this.$emit("change", this.data);
    },
    /**
     * 删除数据，有父组件处理
     */
    deleteData() {
      this.$emit("delete", this.data);
    },

    menuChange(v) {
      this.menuVisible = v;
    }
  }
};
</script>

<style lang="scss" scoped>

  .el-dropdown-menu /deep/ .el-dropdown-menu__item:hover {
    color: white !important;
    background: #3e90fe !important;
  }

  .el-dropdown-menu /deep/ .el-dropdown-menu__item {
    color: #636C78 !important;
    background: white !important;
  }

.cash-object-item {
  width:400px;
  height:152px;
  background: #ffffff;
  border:1px solid #E8ECF2;
  border-radius:4px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
  .content-edit {
    display: none;
    position: absolute;
    right: 13px;
    top: 10px;
    line-height: 5px;
  }
  &:hover {
    .content-edit {
      display: block;
    }
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    border-bottom: 1px solid #E8ECF2;
    padding-left: 13px;
    .en-user-logo {
      border-radius:50%;
    }
    .content-top-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: left;
      /*align-items: center;*/
      margin-left: 12px;
      .content-top-text-name,
      .content-top-text-address {
        font-size:12px;
        font-weight:400;
        line-height: 12px;
        color: #636C78;
      }
      .content-top-text-address {
        margin-top: 10px;
      }
      .content-top-text-number {
        line-height: 14px;
        margin-top: 13px;
        font-size:14px;
        font-weight:bold;
        color: #333333;
      }
    }
  }
  .content-bottom {
    height: 53px;
    display: flex;
    align-items: center;
    background-color: #F6FAFD;
    justify-content: space-between;
    .content-bottom-text {
      margin-left: 12px;
      color: #636C78;
    }
    .content-bottom-input {
      flex: 1;
      margin-left: 28px;
      margin-right: 12px;
      .en-input-number {
        width: 100%;
      }
    }

  }
}

</style>
