<template>
  <en-dialog
    width="50%"
    style="text-align: left"
    :visible="visible || visibleDig"
    title="新增收付信息"
    @close="close"
    :close-on-click-modal="false"
    append-to-body
    class="add-custom-bank-data"
  >
    <div
      v-en-loading="isLoading"
      class="content"
    >
      <el-form
        v-if="hasData"
        label-position="left"
        :model="formData"
        label-width="75px"
        style="with:100%;height: 100%"
      >
        <template v-for="(item) in formItemDatas">
          <el-form-item
            :key="item.field"
            :label="item.name"
            :required="item.required"
          >
            <en-select
              v-if="item.type === 'select'"
              :data="item.data"
              :loading="item.loading"
              mode="normal"
              :placeholder="'请选择' + item.name"
              v-model="formData[item.field]"
              @visible-change="optionsVisibleChange($event, item)"
              @change="valueChange($event, item)"
            >
            </en-select>
            <el-input
              v-else-if="item.type === 'input'"
              :placeholder="'请输入' + item.name"
              v-model="formData[item.field]">
            </el-input>
            <el-checkbox v-else-if="item.type === 'default'" v-model="formData[item.field]">默认</el-checkbox>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div v-if="hasData" slot="footer">
      <en-button @click.native="complete">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { cashObject } from "@/api/businessModel";

export default {
  name: "add-custom-bank-data",
  props: {
    data: {
      type: Object
    },
    visibleDig: {
      type: Boolean
    }
  },
  data() {
    return {
      visible: false,
      isLoading: false,
      dataInfo: null,
      formData: null,
      formItemDatas: []
    };
  },
  computed: {
    hasData() {
      return !!this.dataInfo;
    },
    dataId() {
      return this.data.id;
    },
    objectType() {
      return this.data.objType;
    },
    objectTypeId() {
      return this.data.objTypeId;
    },
    mdData() {
      return this.dataInfo.mdData;
    }
  },
  created() {
    this.queryData(this);
  },
  watch: {
    data: {
      handler() {
        this.queryData(this);
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    close() {
      this.updateFormData();
      this.$emit("close");
    },
    updateItemDatas() {
      const formItemDatas = [];
      function getItemInfo(field, name, type, required = true) {
        const info = {
          field, name, required, loading: false, data: [], type
        };
        return info;
      }
      const bank = getItemInfo("bank", "开户银行", "select");
      const city = getItemInfo("city", "开户城市", "select");
      const bankCode = getItemInfo("bankCode", "开户支行", "select");
      // 城市或者银行改变清空支行
      bank.change = (() => {
        this.formData[bankCode.field] = "";
        bankCode.data = [];
      });
      city.change = bank.change;
      formItemDatas.push(bank, city, bankCode);
      formItemDatas.push(getItemInfo("bankAccountNo", "银行账号", "input"));
      formItemDatas.push(getItemInfo("isDefault", "是否默认", "default", false));
      this.formItemDatas = formItemDatas;
    },
    /**
     * 保存数据
     * @returns {Promise<void>}
     */
    async complete() {
      const mdData = {};
      mdData.name = this.mdData.name;
      mdData.id = this.mdData.id;
      mdData.bankInfo = [{}];
      const bankInfo = mdData.bankInfo[0];
      this.formItemDatas.forEach((item) => {
        const value = this.formData[item.field];
        if (!value) {
          return;
        }
        if (item.type === "select") {
          const data = item.data.filter((item) => item.id === value);
          bankInfo[item.field] = data;
        } else if (item.type === "input") {
          bankInfo[item.field] = value;
        } else if (item.type === "default") {
          bankInfo[item.field] = value ? [{ name: "是", id: "000" }] : [{ name: "否", id: "001" }];
        }
      });
      await cashObject.saveBasicDataDetail(this.objectType, this.objectTypeId, mdData);
      this.$message("保存成功");
      this.visible = false;
      this.$emit("complete");
      this.updateFormData();
    },
    // 获取获取基础数据详情
    async queryData(self) {
      self.isLoading = true;
      self.updateFormData();
      self.updateItemDatas();
      self.dataInfo = await cashObject.queryBasicDataDetail(self.dataId, self.objectType, self.objectTypeId);
      const datas = [];
      self.dataInfo.fieldAttr.forEach((item) => {
        datas.push(...item.fields);
      });
      self.isLoading = false;
    },
    updateFormData() {
      this.formData = {
        bank: null,
        city: null,
        bankCode: null,
        bankAccountNo: "",
        isDefault: false
      };
    },
    /**
     * 获取对应接口数据
     * @param visible
     * @param item
     * @returns {Promise<void>}
     */
    async optionsVisibleChange(visible, item) {
      if (item.data.length === 0 && visible) {
        item.loading = true;
        const datas = await cashObject.queryOptionsData(item.field, this.formData.bank, this.formData.city);
        if (Array.isArray(datas)) {
          datas?.forEach((item) => {
            if (!item.name && item.bankName) {
              item.name = item.bankName;
              item.id = item.bankCode;
            }
            item.label = item.name;
            if (!item.id) {
              item.id = item.code;
            }
            item.value = item.id;
          });
          item.data = datas;
        }
        item.loading = false;
      }
    },
    /**
     * 值改变清空银行支行
     * @param val
     * @param item
     */
    valueChange(val, item) {
      if (item.change) {
        item.change();
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.add-custom-bank-data {
  .content {
    height: 400px;
    .en-select {
      width: 100%;
    }
  }
  /deep/ .el-form-item__content {
    text-align: left;
  }
}

</style>
